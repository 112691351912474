import { useState } from "react";
import PropTypes from "prop-types";
import "../style/SwitchComponent.css";

const SwitchComponent = ({
  options,
  onChange = null,
  ariaLabel = "Switch options",
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    if (index !== activeIndex) {
      setActiveIndex(index);

      const background = document.querySelector(".switch-background");
      background.classList.add("active");

      setTimeout(() => {
        background.classList.remove("active");
      }, 300);

      if (onChange) {
        onChange(options[index]);
      }
    }
  };

  const offsetEm = 0.125;

  return (
    <div className="switch-container" role="group" aria-label={ariaLabel}>
      <div
        className="switch-background"
        style={{
          width: `calc(${100 / options.length}% - ${offsetEm * 2}em)`,
          transform: `translateX(calc(${activeIndex * 100}% + ${
            activeIndex * offsetEm * 2
          }em))`,
        }}
      />
      {options.map((option, index) => (
        <div
          key={index}
          className={`switch-option ${index === activeIndex ? "active" : ""}`}
          onClick={() => handleClick(index)}
          role="switch"
          tabIndex={0}
          aria-checked={index === activeIndex}
          aria-label={`${option} option`}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleClick(index);
            }
          }}
        >
          {option}
        </div>
      ))}
    </div>
  );
};

SwitchComponent.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.element])
  ).isRequired,
  onChange: PropTypes.func,
  ariaLabel: PropTypes.string,
};

export default SwitchComponent;
