import { useState, useEffect, Suspense, lazy } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import BackgroundLayers from "./components/BackgroundLayers";
import HeaderDesktop from "./components/HeaderDesktop";
import HeaderMobile from "./components/HeaderMobile";
import Hero from "./components/Hero";

const About = lazy(() => import("./components/About"));
const Services = lazy(() => import("./components/Services"));
const Gallery = lazy(() => import("./components/Gallery"));
const Contact = lazy(() => import("./components/Contact"));
const CV = lazy(() => import("./components/CV"));
const Testimonials = lazy(() => import("./components/Testimonials"));
const Footer = lazy(() => import("./components/Footer"));

const LoadingFallback = () => (
  <div className="loading-skeleton" aria-label="Loading content">
    <div className="loading-pulse"></div>
  </div>
);

const ErrorBoundary = ({ children }) => {
  const hasError = false;

  return hasError ? (
    <div role="alert" aria-label="Fel inträffade">
      <h1>Något gick fel!</h1>
    </div>
  ) : (
    children
  );
};

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

const App = () => {
  const [isMobileView, setIsMobileView] = useState(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const aspectRatio = width / height;
    return width <= 768 || height <= 1024 || aspectRatio < 0.65;
  });

  const [language, setLanguage] = useState("en");
  const [theme, setTheme] = useState("dark");
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const hotjarSiteId = import.meta.env.VITE_HOTJAR_SITE_ID;
    const hotjarVersion = import.meta.env.VITE_HOTJAR_VERSION;

    if (hotjarSiteId && hotjarVersion) {
      (function (h, o, t, j, a, r) {
        h.hj =
          h.hj ||
          function () {
            (h.hj.q = h.hj.q || []).push(arguments);
          };
        h._hjSettings = {
          hjid: parseInt(hotjarSiteId, 10),
          hjsv: parseInt(hotjarVersion, 10),
        };
        a = o.getElementsByTagName("head")[0];
        r = o.createElement("script");
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
    }
  }, []);

  useEffect(() => {
    const preloadComponents = async () => {
      if ("requestIdleCallback" in window) {
        window.requestIdleCallback(async () => {
          const components = [
            import("./components/About"),
            import("./components/Services"),
            import("./components/Gallery"),
            import("./components/Contact"),
            import("./components/CV"),
            import("./components/Testimonials"),
            import("./components/Footer"),
          ];
          await Promise.all(components);
        });
      }
    };
    preloadComponents();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const aspectRatio = width / height;
      setIsMobileView(width <= 768 || height <= 1024 || aspectRatio < 0.65);
    };
    const debounce = (func, wait) => {
      let timeout;
      return () => {
        clearTimeout(timeout);
        timeout = setTimeout(func, wait);
      };
    };
    const debouncedResize = debounce(handleResize, 200);

    window.addEventListener("resize", debouncedResize);
    return () => window.removeEventListener("resize", debouncedResize);
  }, []);

  useEffect(() => {
    const root = document.documentElement;

    const themeVariables = {
      dark: {
        "--background-color": "#1c1c1c",
        "--background-color-2": "#2a2a2a",
        "--background-color-opacity": "rgba(28, 28, 28, 0.8)",
        "--text-color": "#f0e7d8",
        "--accent-color-large": "#a67c53",
        "--accent-color-small": "#7f5f37",
        "--border-color": "#a1866f",
        "--ripple-color": "rgba(166, 124, 83, 0.7)",
        "--switch-color": "rgba(150, 89, 20, 0.87)",
        "--box-shadow-static": "0 4px 20px rgba(0, 0, 0, 0.5);",
        "--box-shadow-item": "0 10px 15px rgba(0, 0, 0, 0.7);",
        "--box-shadow-pressed-item": "3px 7px 10px rgba(0, 0, 0, 0.9);",
      },
      light: {
        "--background-color": "#f5f1e6",
        "--background-color-2": "#e8e2d3",
        "--background-color-opacity": "rgba(245, 241, 230, 0.8)",
        "--text-color": "#2d1810",
        "--accent-color-large": "#5c8c54",
        "--accent-color-small": "#446740",
        "--border-color": "#8fa680",
        "--ripple-color": "rgba(45, 24, 16, 0.7)",
        "--switch-color": "rgba(140, 190, 120, 0.8)",
        "--box-shadow-static": "0 4px 20px rgba(0, 0, 0, 0.5);",
        "--box-shadow-item": "0 10px 15px rgba(0, 0, 0, 0.7);",
        "--box-shadow-pressed-item": "3px 7px 10px rgba(0, 0, 0, 0.9);",
      },
    };

    const variables = themeVariables[theme];
    Object.entries(variables).forEach(([key, value]) =>
      root.style.setProperty(key, value)
    );
  }, [theme]);

  const toggleLanguage = () => {
    setLanguage((prevLang) => (prevLang === "en" ? "sv" : "en"));
  };

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "dark" ? "light" : "dark"));
  };

  return (
    <div className="app">
      <BackgroundLayers theme={theme} />
      <header aria-label="Navigationsmeny">
        {isMobileView ? (
          <HeaderMobile
            language={language}
            toggleLanguage={toggleLanguage}
            theme={theme}
            toggleTheme={toggleTheme}
            isModalOpen={isModalOpen}
          />
        ) : (
          <HeaderDesktop
            language={language}
            toggleLanguage={toggleLanguage}
            theme={theme}
            toggleTheme={toggleTheme}
            isModalOpen={isModalOpen}
          />
        )}
      </header>

      <main className="content">
        <Hero language={language} />
        <ErrorBoundary>
          <Suspense fallback={<LoadingFallback />}>
            <motion.div layout className="section">
              <About language={language} />
            </motion.div>
            <motion.div layout className="section">
              <Testimonials language={language} />
            </motion.div>
            <motion.div layout className="section">
              <CV language={language} />
            </motion.div>
            <motion.div layout className="section">
              <Gallery
                language={language}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
              />
            </motion.div>
            <motion.div layout className="section">
              <Services language={language} />
            </motion.div>
            <motion.div layout className="section">
              <Contact language={language} />
            </motion.div>
          </Suspense>
        </ErrorBoundary>
      </main>

      <Suspense fallback={<LoadingFallback />}>
        <Footer language={language} />
      </Suspense>
    </div>
  );
};

export default App;
