import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import SwitchComponent from "./SwitchComponent";
import "../style/HeaderMobile.css";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

const HeaderMobile = ({
  language,
  toggleLanguage,
  theme,
  toggleTheme,
  isModalOpen,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const handleScroll = useCallback(() => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const isNearBottom = windowHeight + scrollTop >= documentHeight - 100;

    requestAnimationFrame(() => {
      setIsBottom(isNearBottom);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    window.addEventListener("touchmove", handleScroll, { passive: true });

    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
      if (isOpen) {
        document.body.classList.remove("no-scroll");
      }
    };
  }, [handleScroll, isOpen]);

  const observerOptions = useMemo(
    () => ({
      root: null,
      threshold: [0, 0.1, 0.5, 1],
      rootMargin: "-100px 0px 0px 0px",
    }),
    []
  );

  const menuItems = useMemo(
    () => [
      { name: language === "sv" ? "Hem" : "Home", id: "hero" },
      { name: language === "sv" ? "Om" : "About", id: "about" },
      {
        name: language === "sv" ? "Lovord" : "Testimonials",
        id: "testimonials",
      },
      { name: language === "sv" ? "CV" : "Resume", id: "cv-section" },
      { name: language === "sv" ? "Galleri" : "Gallery", id: "gallery" },
      { name: language === "sv" ? "Tjänster" : "Services", id: "services" },
      { name: language === "sv" ? "Kontakt" : "Contact", id: "contact" },
    ],
    [language]
  );

  const themeIcons = useMemo(
    () => [
      <DarkModeOutlinedIcon key="dark" />,
      <LightModeOutlinedIcon key="light" />,
    ],
    []
  );

  const toggleMenu = useCallback(() => {
    setIsOpen((prev) => !prev);
    document.body.classList.toggle("no-scroll");
  }, []);

  const handleIntersection = useCallback(([entry]) => {
    if (entry) {
      requestAnimationFrame(() => {
        setIsBottom(entry.isIntersecting);
      });
    }
  }, []);

  useEffect(() => {
    let observer;
    let element;
    let isCleanup = false;

    const setupObserver = () => {
      observer = new IntersectionObserver(handleIntersection, observerOptions);
      element = document.querySelector("#contact-end");

      if (element && !isCleanup) {
        observer.observe(element);
      }
    };

    setupObserver();

    return () => {
      isCleanup = true;
      if (observer) {
        observer.disconnect();
      }
      if (isOpen) {
        document.body.classList.remove("no-scroll");
      }
    };
  }, [handleIntersection, observerOptions, isOpen]);

  return (
    <header
      className={`mobile-header ${isBottom ? "at-bottom" : ""} ${
        isOpen ? "is-active" : ""
      } ${isModalOpen ? "modal-active" : ""}`}
    >
      <button
        className={`fab-button ${isOpen ? "is-active" : ""} hamburger`}
        onClick={toggleMenu}
        aria-label="Toggle menu"
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
      <div className={`overlay ${isOpen ? "active" : ""}`}>
        <h1 className="sr-only">{language === "sv" ? "Meny" : "Menu"}</h1>
        <ul className="overlay-menu">
          {menuItems.map((section, index) => (
            <li key={index}>
              <a
                href={`/#${section.id}`}
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .querySelector(`#${section.id}`)
                    ?.scrollIntoView({ behavior: "smooth", block: "start" });
                  toggleMenu();
                }}
                aria-label={
                  language === "sv"
                    ? `Gå till ${section.name}`
                    : `Go to ${section.name}`
                }
              >
                {section.name}
              </a>
            </li>
          ))}
          <li>
            <SwitchComponent
              options={["Eng", "Swe"]}
              onChange={toggleLanguage}
              ariaLabel={
                language === "sv"
                  ? "Växla mellan Svenska och Engelska"
                  : "Switch between Swedish and English"
              }
            />
          </li>
          <li>
            <SwitchComponent
              options={themeIcons}
              onChange={toggleTheme}
              ariaLabel={
                theme === "dark"
                  ? "Switch to Light Mode"
                  : "Switch to Dark Mode"
              }
            />
          </li>
        </ul>
      </div>
    </header>
  );
};

HeaderMobile.propTypes = {
  language: PropTypes.string.isRequired,
  toggleLanguage: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  toggleTheme: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
};

export default React.memo(HeaderMobile);
