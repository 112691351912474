import { useEffect, useState, useCallback } from "react";
import sanityClient from "../sanityClient";
import "../style/Hero.css";
import PropTypes from "prop-types";
import fallback from "../assets/fallback_video.gif";

const Hero = ({ language }) => {
  const [videoUrl, setVideoUrl] = useState("");
  const [videoUrlWebM, setVideoUrlWebM] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const maxRetries = 3;
  const retryDelay = 2000;

  const fetchVideosWithRetry = useCallback(async (retryCount = 0) => {
    try {
      setIsLoading(true);

      const data = await sanityClient.fetch(
        `*[_type == "video" && title in ["Hero_h265", "Hero_webm"]]{
          title,
          "videoUrl": videoFile.asset->url
        }`
      );

      const heroH265Video = data.find((item) => item.title === "Hero_h265");
      const heroWebMVideo = data.find((item) => item.title === "Hero_webm");

      if (!heroH265Video?.videoUrl || !heroWebMVideo?.videoUrl) {
        throw new Error("Missing video URLs");
      }

      setVideoUrl(heroH265Video.videoUrl);
      setVideoUrlWebM(heroWebMVideo.videoUrl);
      setHasError(false);
    } catch (error) {
      console.error("Error:", error);
      if (retryCount < maxRetries) {
        setTimeout(() => fetchVideosWithRetry(retryCount + 1), retryDelay);
      } else {
        setHasError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchVideosWithRetry();
  }, [fetchVideosWithRetry]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    const heroElement = document.getElementById("hero");
    if (heroElement) observer.observe(heroElement);

    return () => {
      if (heroElement) observer.unobserve(heroElement);
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    return () => {
      if (videoUrl) {
        URL.revokeObjectURL(videoUrl);
      }
      if (videoUrlWebM) {
        URL.revokeObjectURL(videoUrlWebM);
      }
    };
  }, [videoUrl, videoUrlWebM]);

  const createRipple = (event) => {
    const button = event.currentTarget;
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add("ripple");

    const existingRipple = button.getElementsByClassName("ripple")[0];
    if (existingRipple) {
      existingRipple.remove();
    }

    button.appendChild(circle);
  };

  const renderBackgroundMedia = () => {
    if (isLoading) {
      return (
        <div className="hero2-loading">
          <img src={fallback} alt="Loading" className="hero2-fallback-gif" />
        </div>
      );
    }

    if (hasError || (!videoUrl && !videoUrlWebM)) {
      return (
        <img src={fallback} alt="Fallback" className="hero2-fallback-gif" />
      );
    }

    return (
      <video
        className="hero2-video"
        autoPlay={isIntersecting}
        loop
        muted
        playsInline
        preload="auto"
        aria-hidden="true"
        crossOrigin="anonymous"
        onError={(e) => {
          console.error("Video load error:", {
            error: e,
            videoUrl,
            videoUrlWebM,
          });
          setHasError(true);
        }}
      >
        {videoUrlWebM && (
          <source
            src={videoUrlWebM}
            type="video/webm"
            onError={(e) => console.error("WebM source error:", e)}
          />
        )}
        {videoUrl && (
          <source
            src={videoUrl}
            type='video/mp4; codecs="hvc1"'
            onError={(e) => console.error("MP4 source error:", e)}
          />
        )}
      </video>
    );
  };

  return (
    <div id="hero" className="hero2-container">
      <div className="video-overlay">{renderBackgroundMedia()}</div>
      <div className="hero2-content">
        <h2 className="hero2-subtitle-top">
          {language === "sv" ? "Kreativ Utvecklare" : "Creative Developer"}
        </h2>
        <h1 className="hero2-name">Adam Lundstedt</h1>
        <p className="hero2-subtitle-bottom">
          {language === "sv"
            ? "Design som berör och inspirerar."
            : "Design that touches and inspires."}
        </p>
        <a
          href="/#about"
          className="hero2-cta"
          onClick={(e) => {
            e.preventDefault();
            createRipple(e);
            document
              .querySelector("#about")
              ?.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
          aria-label={
            language === "sv" ? "Läs mer om mig" : "Read more about me"
          }
        >
          {language === "sv" ? "Läs mer" : "Read more"}
        </a>
      </div>
    </div>
  );
};

Hero.propTypes = {
  language: PropTypes.string.isRequired,
};

export default Hero;
