import { useEffect, useState, useRef, useCallback } from "react";
import sanityClient, { urlFor } from "../sanityClient";
import PropTypes from "prop-types";
import "../style/BackgroundLayers.css";
import lightBgMobile from "../assets/light_background.webp";
import darkBgMobile from "../assets/dark_background.webp";

const BackgroundLayers = ({ theme }) => {
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [scrollY, setScrollY] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const imageCache = useRef(new Map());

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const getImageUrl = useCallback(
    (asset, isFirst = false) => {
      const width = isMobile ? (isFirst ? 300 : 500) : isFirst ? 500 : 1200;
      const quality = isMobile ? 50 : 75;

      return urlFor(asset).width(width).quality(quality).format("webp").url();
    },
    [isMobile]
  );

  const preloadImage = useCallback(
    (image, isFirst = false) => {
      const url = getImageUrl(image.asset, isFirst);

      if (imageCache.current.has(url)) {
        return imageCache.current.get(url);
      }

      const loadPromise = new Promise((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve(url);
        img.onerror = reject;
      });

      imageCache.current.set(url, loadPromise);
      return loadPromise;
    },
    [getImageUrl]
  );

  const loadImages = useCallback(
    async (images) => {
      for (let i = 0; i < images.length; i++) {
        await preloadImage(images[i], i === 0);
      }
    },
    [preloadImage]
  );

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    const fetchImages = async () => {
      try {
        const data = await sanityClient.fetch(`
          *[_type == "photoGallery" && title == "background"][0]{
            images[] {
              asset -> { _id, url },
              _key
            }
          }
        `);

        if (mounted && data?.images) {
          setBackgroundImages(data.images);
          await loadImages(data.images);
        }
      } catch (error) {
        console.error("Error loading images:", error);
      } finally {
        if (mounted) setIsLoading(false);
      }
    };

    fetchImages();
    return () => {
      mounted = false;
    };
  }, [loadImages]);

  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(() => {
        setScrollY(window.scrollY);
      });
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (isLoading) return <div className="background-loading" />;

  if (isMobile) {
    const translateY = scrollY * -0.1;
    return (
      <div className="background-container">
        <div
          className="background-layer mobile"
          style={{
            backgroundImage: `url(${
              theme === "dark" ? darkBgMobile : lightBgMobile
            })`,
            transform: `translateY(${translateY}px)`,
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            transition:
              "transform 0.3s ease-out, background-image 0.3s ease-in-out",
          }}
        />
      </div>
    );
  }

  return (
    <div className="background-container">
      {backgroundImages.map((bg, index) => (
        <LazyBackgroundLayer
          key={bg._key}
          imageUrl={getImageUrl(bg.asset)}
          scrollY={scrollY}
          index={index}
          priority={index === 0}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
};

BackgroundLayers.propTypes = {
  theme: PropTypes.oneOf(["light", "dark"]).isRequired,
};

const LazyBackgroundLayer = ({
  imageUrl,
  scrollY,
  index,
  priority,
  isMobile,
}) => {
  const [isVisible, setIsVisible] = useState(priority);
  const ref = useRef();

  useEffect(() => {
    if (priority) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        rootMargin: "50px",
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, [priority]);

  const translateYValue = scrollY * -0.1;

  return (
    <div
      ref={ref}
      className="background-layer"
      style={{
        backgroundImage: isVisible ? `url(${imageUrl})` : "none",
        transform: `translateY(${translateYValue}px)`,
        opacity: isVisible ? 1 : 0,
        position: "absolute",
        top: `${index * 400}px`,
        left: index % 2 === 0 ? "10%" : "55%",
        width: isMobile ? "40vw" : "30vw",
        height: isMobile ? "50vh" : "40vh",
        borderRadius: "10px",
        boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        transition: "opacity 0.3s ease-in, transform 0.3s ease-out",
      }}
    ></div>
  );
};

LazyBackgroundLayer.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  scrollY: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  priority: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default BackgroundLayers;
