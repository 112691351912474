import { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import "../style/HeaderDesktop.css";
import SwitchComponent from "./SwitchComponent";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

const THEME_ICONS = [
  <DarkModeOutlinedIcon key="dark" />,
  <LightModeOutlinedIcon key="light" />,
];

const HeaderDesktop = ({
  language,
  toggleLanguage,
  isModalOpen,
  theme,
  toggleTheme,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const lastScrollY = useRef(window.scrollY);
  const themeIcons = useMemo(() => THEME_ICONS, []);

  useEffect(() => {
    if (isModalOpen) {
      setIsVisible(false);
      return;
    }

    const handleScroll = () => {
      setIsVisible(window.scrollY < lastScrollY.current);
      lastScrollY.current = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isModalOpen]);

  return (
    <header className={`desktop-header ${isVisible ? "visible" : "hidden"}`}>
      <h1 className="sr-only">
        {language === "sv"
          ? "Välkommen till min hemsida"
          : "Welcome to my website"}
      </h1>
      <nav className="nav-desktop" aria-label="Main navigation">
        <ul>
          {[
            {
              id: "hero",
              label: language === "sv" ? "Hem" : "Home",
              href: "/#hero",
            },
            {
              id: "about",
              label: language === "sv" ? "Om" : "About",
              href: "/#about",
            },
            {
              id: "testimonials",
              label: language === "sv" ? "Lovord" : "Testimonials",
              href: "/#testimonials",
            },
            {
              id: "cv-section",
              label: language === "sv" ? "CV" : "Resume",
              href: "/#cv-section",
            },
            {
              id: "gallery",
              label: language === "sv" ? "Galleri" : "Gallery",
              href: "/#gallery",
            },
            {
              id: "services",
              label: language === "sv" ? "Tjänster" : "Services",
              href: "/#services",
            },
            {
              id: "contact",
              label: language === "sv" ? "Kontakt" : "Contact",
              href: "/#contact",
            },
          ].map((item) => (
            <li key={item.id}>
              <a
                href={item.href}
                aria-label={
                  language === "sv"
                    ? `Gå till ${item.label}`
                    : `Go to ${item.label}`
                }
                aria-current={
                  window.location.hash === `#${item.id}` ? "page" : undefined
                }
                onClick={(e) => {
                  e.preventDefault();
                  document
                    .querySelector(`#${item.id}`)
                    ?.scrollIntoView({ behavior: "smooth", block: "start" });
                }}
                className="desktop-link"
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <div className="switch-container-multi">
        <div className="switch-container">
          <SwitchComponent
            options={["Eng", "Swe"]}
            onChange={toggleLanguage}
            ariaLabel={
              language === "sv"
                ? "Växla mellan Svenska och Engelska"
                : "Switch between Swedish and English"
            }
          />
        </div>
        <div className="switch-container">
          <SwitchComponent
            options={themeIcons}
            onChange={toggleTheme}
            ariaLabel={
              theme === "dark" ? "Switch to Light Mode" : "Switch to Dark Mode"
            }
          />
        </div>
      </div>
    </header>
  );
};

HeaderDesktop.propTypes = {
  language: PropTypes.string.isRequired,
  toggleLanguage: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  theme: PropTypes.string.isRequired,
  toggleTheme: PropTypes.func.isRequired,
};

export default HeaderDesktop;
